<template>
  <div class="text-right">
    <v-dialog v-model="dialog" persistent height="auto" width="1150">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
          outlined
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="tway-violet--text accent-4"> mdi-chart-line </v-icon>
          Seguimiento
        </v-btn>
      </template>
      <v-card :class="'modalSystem'">
        <v-card>
          <div class="container">
            <h3 class="text-center">Seguimiento Desempeño Organizacional</h3>
            <v-btn icon right @click="dialog = false" class="float-right">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <!-- TALENTO DIGITAL -->
            <form>
              <div class="d-flex mt-4 justify-space-around">
                <div class="row justify-space-around">
                  <div class="col-12 mt-8">
                    Talento Digital
                    <v-divider class="divisor"></v-divider>
                  </div>
                  <div class="col-2">
                    <v-text-field type="text" label="Incorporación" v-model="fixedData.incorporation" outlined />
                  </div>
                  <div class="col-2">
                    <v-text-field type="text" label="Rendimiento" v-model="fixedData.yield" outlined />
                  </div>
                  <div class="col-2">
                    <v-text-field type="text" label="Rotación" v-model="fixedData.rotation" outlined />
                  </div>
                  <div class="col-3">
                    <v-text-field
                      type="text"
                      label="Capacitación en Tecnologías y Sistemas"
                      v-model="fixedData.techAndSysTraining"
                      outlined
                    />
                  </div>
                  <div class="col-3">
                    <v-text-field
                      type="text"
                      label="Mejora en Habilidades Digitales"
                      v-model="fixedData.skillsImprovement"
                      outlined
                    />
                  </div>
                </div>
              </div>
            </form>
            <!-- CAPACIDADES DIGITALES -->
            <form>
              <div class="d-flex mt-4 justify-space-around">
                <div class="row justify-space-around">
                  <div class="col-12 mt-8">
                    Capacidades Digitales
                    <v-divider class="divisor"></v-divider>
                  </div>
                  <div class="col-4">
                    <v-text-field
                      type="text"
                      label="Herramientas para Estrategia Digital"
                      v-model="fixedData.toolsAvailability"
                      outlined
                    />
                  </div>
                  <div class="col-4">
                    <v-text-field
                      type="text"
                      label="Time to Market por Innovación"
                      v-model="fixedData.timeToMarket"
                      outlined
                    />
                  </div>
                  <div class="col-4">
                    <v-text-field
                      type="text"
                      label="Adopción de Aplicaciones y Tencologías"
                      v-model="fixedData.appAndTechAdoption"
                      outlined
                    />
                  </div>
                </div>
              </div>
            </form>
            <!-- RETENCIÓN DE CLIENTES -->
            <form>
              <div class="d-flex justify-space-around mt-1">
                <div class="row justify-start">
                  <div class="col-12 mt-8">
                    Retención de Clientes
                    <v-divider class="divisor"></v-divider>
                  </div>
                  <div class="col-4">
                    <v-text-field
                      label="Tasa de Resolución en Primera Línea"
                      type="text"
                      v-model="fixedData.resolutionRate"
                      outlined
                    />
                  </div>
                  <div class="col-4">
                    <v-text-field
                      label="Desviación en Procesos Críticos"
                      type="text"
                      v-model="fixedData.deviationTime"
                      outlined
                    />
                  </div>
                </div>
              </div>
            </form>
            <!-- RIESGO DE DATOS -->
            <form>
              <div class="d-flex mt-4 justify-space-around">
                <div class="row justify-start">
                  <div class="col-12 mt-8">
                    Riesgo de Datos
                    <v-divider class="divisor"></v-divider>
                  </div>
                  <div class="col-4">
                    <v-text-field
                      type="text"
                      label="Rotación de Clientes por Filtración de Datos"
                      v-model="fixedData.clientRotation"
                      outlined
                    />
                  </div>
                  <div class="col-4">
                    <v-text-field
                      type="text"
                      label="Eventos de Riesgo Críticos por Manejo de Datos"
                      v-model="fixedData.riskEvents"
                      outlined
                    />
                  </div>
                </div>
              </div>
            </form>
            <v-divider class="divisor"></v-divider>
            <!-- TABLA PARA INGRESAR DATOS NUEVOS -->
            <h3 class="text-center my-5">Añadir Desempeño</h3>
            <v-simple-table class="table mt-5" fixed-header>
              <template>
                <thead>
                  <tr>
                    <th class="text-center" rowspan="2">Mes / Año</th>
                    <th class="text-center">Retención de Clientes</th>
                    <th class="text-center" colspan="2">Riesgo de Datos</th>
                    <th class="text-center" rowspan="2"></th>
                  </tr>
                  <tr>
                    <th class="text-center separator">Tiempo para Resolver Incidencia Crítica</th>
                    <th class="text-center">Clientes Potenciales Perdidos por Filtración de Datos</th>
                    <th class="text-center">Tiempo de Detección de Filtración de Datos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">
                      <div>
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="true"
                          :return-value.sync="date"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="items.date"
                              prepend-icon="mdi-calendar"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="items.date"
                            type="month"
                            no-title
                            @input="menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                    </td>
                    <td class="text-center separator">
                      <v-text-field v-model="items.hoursToResolve" outlined blur></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field v-model="items.potentialCustomerLoss" outlined blur></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field v-model="items.quickResponse" outlined blur></v-text-field>
                    </td>
                    <td>
                      <div class="text-center d-flex justify-center pa-md-6">
                        <v-btn
                          outlined
                          color="indigo lighten-2"
                          class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
                          @click.stop="savePerformanceTrace(items)"
                        >
                          <div>
                            <v-icon class="tway-violet--text"> mdi-plus </v-icon>
                            <span v-if="!loading.saveStage">Agregar</span>
                            <span v-else>Guardando...</span>
                          </div>
                        </v-btn>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-divider class="my-6"></v-divider>
            <v-alert
              v-model="alert.savePerformance"
              border="right"
              colored-border
              type="error"
              elevation="2"
              class="ml-2 mr-2"
            >
              {{ messageError.savePerformance }}
            </v-alert>
            <h4 class="text-center mt-10 font-weight-regular">Listado de Seguimiento Desempeño Organizacional</h4>
            <v-alert
              v-model="alert.updatePerformance"
              border="right"
              colored-border
              type="error"
              elevation="2"
              class="ml-2 mr-2"
            >
              {{ messageError.updatePerformance }}
            </v-alert>
            <!-- TABLA LISTADO SEGUIMIENTO DESEMPEñO -->
            <v-simple-table class="table mt-5">
              <template>
                <thead>
                  <tr>
                    <th class="text-left" scope="col">ID</th>
                    <th class="text-left" scope="col">Fecha</th>
                    <th class="text-left" scope="col">Horas para Resolver</th>
                    <th class="text-left" scope="col">Clientes Potenciales Perdidos</th>
                    <th class="text-left" scope="col">Minutos Respuesta</th>
                    <th class="text-left" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    :v-if="performanceTrace.length > 0"
                    v-for="entry in performanceTrace"
                    :key="entry.idPerformanceTrace"
                    :class="entry.isVisible ? hidden : activeClass"
                    class="edit_row"
                  >
                    <th lass="text-center" scope="row">
                      {{ entry.idPerformanceTrace }}
                    </th>
                    <td class="text-center">
                      <v-menu
                        ref="menu"
                        v-model="entry.menu"
                        :close-on-content-click="true"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="entry.date"
                            prepend-icon="mdi-calendar"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="entry.date" type="month" no-title scrollable> </v-date-picker>
                      </v-menu>
                    </td>
                    <td class="text-center">
                      <v-text-field v-model="entry.hoursToResolve" outlined></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field v-model="entry.potentialCustomerLoss" outlined></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-text-field v-model="entry.quickResponse" outlined></v-text-field>
                    </td>
                    <td class="text-center buttons">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="primary" class="edit" @click="saveItem(entry)" v-bind="attrs" v-on="on">
                            <v-icon>edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="primary"
                            class="save"
                            @click="updatePerformanceTrace(entry)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>save</v-icon>
                          </v-btn>
                        </template>
                        <span>Guardar</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                            @click="deletePerformanceTrace(entry.idPerformanceTrace)"
                          >
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Eliminar</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <!-- BOTON GUARDAR -->
            <div class="d-flex mt-12 justify-space-around">
              <div class="row">
                <div class="col-12 text-center justify-center pa-md-5 mx-lg-auto">
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="indigo lighten-2"
                    class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text mx-lg-auto"
                    @click="closeConfigurationDialog()"
                  >
                    <v-icon class="tway-violet--text">mdi-content-save</v-icon>
                    Guardar
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
    <confirmation ref="confirm" :message="dialogMessage.title" :message-body="dialogMessage.body"></confirmation>
  </div>
</template>

<script>
import performanceService from "../../../services/pmo/performance/performanceService";
import Confirmation from "../utils/ConfirmationDialog.vue";

export default {
  components: { Confirmation },
  data: (vm) => {
    return {
      projectDateProposal: "",
      menu6: false,
      on: false,
      isVisible: false,
      activeClass: "is-visible",
      dialogMessage: {
        title: "",
        body: "",
      },
      dateFormatted: vm.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
      ),
      loading: {
        saveStage: false,
        saveActivity: false,
      },
      alert: {
        savePerformance: false,
        updatePerformance: false,
      },
      messageError: {
        savePerformance: "",
        updatePerformance: "",
      },
      activity: {
        stageSelected: 0,
        name: "",
        dateStart: "",
        dateEnd: "",
        responsableSelected: 0,
      },
      stage: {
        name: "",
        dateStart: "",
        dateEnd: "",
      },
      fixedData: {
        index: "",
        resolutionRate: "",
        deviationTime: "",
        clientRotation: "",
        riskEvents: "",
        incorporation: "",
        yield: "",
        rotation: "",
        toolsAvailability: "",
        techAndSysTraining: "",
        skillsImprovement: "",
        timeToMarket: "",
        appAndTechAdoption: "",
      },
      items: {
        hoursToResolve: "",
        potentialCustomerLoss: "",
        quickResponse: "",
        date: new Date().toISOString().substr(0, 7),
      },
      performanceTrace: [],
      dialog: false,
      menu: false,
      modal: false,
      startDateProposal: "",
      endDateProposal: "",
      etapas: [],
      combos: {
        selectEtapas: [],
        responsible: [],
      },
      setup: null,
      activities: [],
    };
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  computed: {
    sortedList: function () {
      return this.allScores.slice().sort(function (a, b) {
        return b.score - a.score;
      });
    },
    sortedList2: function () {
      return this.allScor.slice().sort(function (a, b) {
        return b.score - a.score;
      });
    },
    computedStartDateProposal() {
      return this.formatDate(this.setup.startDateProposal);
    },
    // computedStageDateStart() {
    //   return this.formatDate(this.stage.dateStart);
    // },
    // computedStageDateEnd() {
    //   return this.formatDate(this.stage.dateEnd);
    // },
    // computedDateFormatted5() {
    //   return this.formatDate(this.activity.dateStart);
    // },
    // computedDateFormatted6() {
    //   return this.formatDate(this.activity.dateEnd);
    // },
    computedEndDateProposal(val) {
      return val;
    },
  },
  methods: {
    clearForm() {
      this.items.hoursToResolve = "";
      this.items.potentialCustomerLoss = "";
      this.items.quickResponse = "";
    },
    saveItem(item) {
      item.isVisible = !item.isVisible;
      this.$forceUpdate();
    },
    closeConfigurationDialog() {
      this.dialog = false;
      this.$emit("reloadPerformanceCharts");
      this.saveFixedData();
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    saveProject() {},
    saveFixedData() {
      let request = {
        index: this.fixedData.index,
        enterpriseId: this.project.enterpriseId,
        projectId: this.project.projectId,
        resolutionRate: this.fixedData.resolutionRate,
        deviationTime: this.fixedData.deviationTime,
        clientRotation: this.fixedData.clientRotation,
        riskEvents: this.fixedData.riskEvents,
        incorporation: this.fixedData.incorporation,
        yield: this.fixedData.yield,
        rotation: this.fixedData.rotation,
        toolsAvailability: this.fixedData.toolsAvailability,
        techAndSysTraining: this.fixedData.techAndSysTraining,
        skillsImprovement: this.fixedData.skillsImprovement,
        timeToMarket: this.fixedData.timeToMarket,
        appAndTechAdoption: this.fixedData.appAndTechAdoption,
      };
      performanceService
        .saveFixedData(request)
        .then(() => {
          this.getFixedData();
        })
        .catch((err) => console.dir(err));
    },
    getFixedData() {
      performanceService
        .getFixedData(this.project)
        .then((data) => {
          this.fixedData = data;
        })
        .catch((err) => {
          console.dir(err);
        })
        .finally(() => {
          this.loading.saveUnit = false;
          setTimeout(() => (this.alert.saveUnit = false), 4000);
        });
    },
    savePerformanceTrace(items) {
      let request = {
        enterpriseId: this.project.enterpriseId,
        projectId: this.project.projectId,
        hoursToResolve: items.hoursToResolve,
        potentialCustomerLoss: items.potentialCustomerLoss,
        quickResponse: items.quickResponse,
        date: items.date,
      };
      performanceService
        .savePerformanceTrace(request)
        .then(() => {
          this.clearForm();
          this.getPerformanceTrace();
        })
        .catch((err) => {
          let message = err.response.data.message;
          this.alert.savePerformance = true;
          this.messageError.savePerformance = message;
        })
        .finally(() => {
          setTimeout(() => (this.alert.savePerformance = false), 4000);
        });
    },
    updatePerformanceTrace(item) {
      let request = {
        enterpriseId: this.project.enterpriseId,
        projectId: this.project.projectId,
        idPerformanceTrace: item.idPerformanceTrace,
        hoursToResolve: item.hoursToResolve,
        potentialCustomerLoss: item.potentialCustomerLoss,
        quickResponse: item.quickResponse,
        date: item.date,
      };
      performanceService
        .savePerformanceTrace(request)
        .then(() => {
          this.clearForm();
          this.saveItem(item);
        })
        .catch((err) => {
          let message = err.response.data.message;
          this.alert.updatePerformance = true;
          this.messageError.updatePerformance = message;
        })
        .finally(() => {
          setTimeout(() => (this.alert.updatePerformance = false), 4000);
        });
    },
    getPerformanceTrace() {
      performanceService
        .getPerformanceTrace(this.project)
        .then((data) => {
          this.performanceTrace = data;
        })
        .catch((err) => {
          console.dir(err);
        })
        .finally(() => {
          this.loading.saveUnit = false;
          setTimeout(() => (this.alert.saveUnit = false), 4000);
        });
    },
    deletePerformanceTrace(entry) {
      performanceService
        .deletePerformanceTrace(this.project, entry)
        .then(() => {
          this.getPerformanceTrace();
        })
        .catch((err) => {
          console.dir(err);
        })
        .finally(() => {
          this.loading.saveUnit = false;
          setTimeout(() => (this.alert.saveUnit = false), 4000);
        });
    },
  },
  mounted() {
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.project = pmoProjectIds;

    this.getFixedData();
    this.getPerformanceTrace();
    this.clearForm();

    this.reload();
  },
};
</script>

<style>
.shrink {
  width: 150px;
}
.bar {
  width: 350px;
}
.modalSystem {
  border-radius: 0px !important;
}
.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  background-color: #fff;
}
.modalSystem .v-card.v-sheet.theme--light {
  padding: 20px 40px;
}
.modalSystem label.v-label.theme--light,
.edit_row .v-text-field__details {
  font-family: "Josefin Sans", sans-serif !important;
}
.modalSystem fieldset {
  border-color: #4a148c !important;
}
.modalSystem label.v-label--active {
  color: #4a148c !important;
}
.modalSystem button.v-btn.primary {
  background-color: #4a148c !important;
  border-color: #4a148c !important;
}
.edit_row .v-input__slot,
.edit_row .v-select {
  min-height: 35px !important;
  max-height: 35px !important;
  margin-bottom: 0px !important;
}
.edit_row .v-text-field--outlined .v-label {
  top: 8px;
  font-size: 13px !important;
}
.edit_row .v-input__append-inner {
  margin-top: 5px !important;
}
.edit_row .v-text-field__details {
  display: none;
}
.edit_row .v-select__selection,
.edit_row .v-text-field__slot input {
  font-size: 0.875rem;
}
.edit_row label.v-label.v-label--active.theme--light {
  top: 18px;
}
tr.edit_row td {
  height: 60px !important;
}
.modalSystem .edit_row fieldset {
  border-color: #fff !important;
  background-color: #f2f2f2;
}
.modalSystem .v-data-table__wrapper {
  max-height: 408px;
  overflow: auto;
}
.modalSystem button.float-right {
  z-index: 9;
  top: 15px;
  right: 15px;
  position: absolute;
}
.modalSystem .edit_row .v-input__prepend-outer {
  display: none;
}
.peso {
  float: left;
  margin-right: 5px;
  top: 7px;
  position: relative;
}
tr.edit_row td .edit {
  display: none;
}
tr.edit_row.is-visible td {
  pointer-events: none;
}
tr.edit_row.is-visible td.buttons {
  pointer-events: visible;
}
tr.edit_row.is-visible td .save {
  display: none !important;
}
tr.edit_row.is-visible td .edit {
  display: inline-grid !important;
}
tr.edit_row.is-visible .v-input__append-inner {
  display: none !important;
}
tr.edit_row.is-visible label.v-label.v-label--active.theme--light {
  display: none;
}
.modalSystem .edit_row.is-visible fieldset {
  border-color: transparent !important;
  background-color: transparent;
}
.separator {
  border-right: 2px solid rgb(225, 223, 223);
}
</style>
