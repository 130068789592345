<template>
  <div class="box-graphics">
    <div class="d-flex justify-end">
      <seguimiento class="ml-5" @reloadPerformanceCharts="reloadCharts()"></seguimiento>
    </div>
    <h3 class="text-center tway-header-1">Desempeño Organizacional</h3>
    <!-- Talento Digital -->
    <div class="graphics-container-triple padding-fix">
      <div class="tway-violet--text">Talento Digital</div>
      <div>
        <!-- Incorporación y Rendimiento -->
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Incorporación
              <ToolTips
                width="300px"
                description="Cantidad de nuevos profesionales capaces de desarrollar una actividad utilizando sus competencias digitales para la nueva economía actual."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              :series="chartIncorporation.series"
              :options="chartIncorporation.chartOptions"
              height="290"
              type="radialBar"
            />
          </div>
        </div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Rendimiento
              <ToolTips
                width="320px"
                description="Mide de forma periódica, cuantitativamente y cualitativamente, la forma en que un talento con competencias digitales cumple tareas y funciones encomendadas para lograr su éxito."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              :series="chartYield.series"
              :options="chartYield.chartOptions"
              height="290"
              type="radialBar"
            />
          </div>
        </div>
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Rotación
              <ToolTips
                width="300px"
                description="Cantidad de talentos digitales nuevos que reemplazan a los que se retiran voluntaria o involuntariamente de una empresa."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              :series="chartRotation.series"
              :options="chartRotation.chartOptions"
              height="290"
              type="radialBar"
            />
          </div>
        </div>
      </div>
      <!-- Capacitación en TyS & Mejora en HD -->
      <div class="mt-8 box-container">
        <!-- Capacitacion en Tecnologías y Sistemas -->
        <div class="inside-box">
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Capacitación en Tecnologías y Sistemas
              <ToolTips
                width="310px"
                description="Cantidad de cursos de formación en temas de sistemas y tecnología que se imparten a la fuerza de trabajo en una empresa."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              :series="chartTechAndSystemTraining.series"
              :options="chartTechAndSystemTraining.chartOptions"
              height="290"
              type="radialBar"
            />
          </div>
        </div>
        <!-- Mejora en Habilidades Digitales -->
        <div class="inside-box">
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Mejora en Habilidades Digitales
              <ToolTips
                width="325px"
                description="Aumento en el nivel de dominio de habilidades digitales de trabajadores en una empresa."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              :series="chartDigitalSkillsImprovement.series"
              :options="chartDigitalSkillsImprovement.chartOptions"
              height="290"
              type="radialBar"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Capacidades Digitales -->
    <div class="graphics-container-triple">
      <div class="tway-violet--text">Capacidades Digitales</div>
      <!-- Herramientas Digitales & Time to Market & Adopcion tecnologias -->
      <div class="box-container">
        <!-- Herramientas Estrategia Digital -->
        <div class="inside-box">
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Herramientas para Estrategia Digital
              <ToolTips
                width="330px"
                description="Existencia y acceso a herramientas tecnológicas para llevar a cabo las tareas que involucra una estrategia digital empresarial."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              :series="chartToolsAvailability.series"
              :options="chartToolsAvailability.options"
              height="300"
              type="radialBar"
            />
          </div>
        </div>
        <!-- Time to Market -->
        <div class="inside-box">
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p>
              Time to Market por Innovación
              <ToolTips
                width="310px"
                description="
              Reducción en el tiempo de comercialización de un producto o servicio a causa de iniciativas de innovación."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              :series="chartTimeToMarket.series"
              :options="chartTimeToMarket.options"
              height="300"
              type="radialBar"
            />
          </div>
        </div>
        <!-- Adopción Aplicaciones y Tecnologias -->
        <div class="inside-box">
          <div class="mt-1 pt-2 tway-violet--text">
            <p>
              Adopción de Aplicaciones y Tecnologías
              <ToolTips
                width="350"
                description="Uso y adherencia a las tecnologías y aplicaciones en una empresa, por parte de sus trabajadores."
                formula="(Cantidad de aplicaciones - soluciones innovadoras utilzadas) x 100 / Total"
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              :series="chartAppAndTechAdoption.series"
              :options="chartAppAndTechAdoption.options"
              height="300"
              type="radialBar"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Retención de Clientes -->
    <div class="graphics-container-triple">
      <div class="tway-violet--text">Retención de Clientes</div>
      <div>
        <!-- Tasa Resolución Primera Línea -->
        <div>
          <div class="mx-6 mt-1 pt-2 tway-violet--text">
            <p class="pe-10">
              Tasa de Resolución en Primera Línea
              <ToolTips
                width="300px"
                description="Relación entre cantidad de llamadas a un centro de atención y su solución por los agentes a cargo en el primer contacto."
              />
            </p>
          </div>
          <div class="d-flex justify-content-center">
            <VueApexCharts
              :series="chartResolutionRate.series"
              :options="chartResolutionRate.chartOptions"
              height="290"
              type="radialBar"
            />
          </div>
        </div>
        <!-- Desviación Procesos Criticos -->
        <div>
          <div class="mx-6 pe-2 mt-1 pt-2 tway-violet--text">
            <p class="me-10 pe-10">
              Desviación en Procesos Críticos
              <ToolTips
                width="350"
                description="Diferencia entre tiempo estimado y tiempo real empleado en la resolución de procesos vitales y determinantes para el modelo de negocio y que se bordan con máxima prioridad."
                formula="(Tiempo empleado - Tiempo planificado) x 100 / Tiempo planificado."
                objective="Objetivo del KPI: ≤ 0%."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              :series="chartTimeDeviationCP.series"
              :options="chartTimeDeviationCP.options"
              height="290"
              type="radialBar"
            />
          </div>
        </div>
        <!-- Tiempo Resolver Incidencia Critica -->
        <div>
          <div class="mx-4 mt-1 pt-2 tway-violet--text">
            <p class="pe-6">
              Tiempo para Resolver Incidencia Crítica
              <ToolTips
                width="300px"
                description="Cantidad de tiempo en horas que se destinan para solucionar una incidencia digital con gran impacto en el negocio."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="chartHourToResolveCI"
              :series="chartHourToResolveCI.series"
              :options="chartHourToResolveCI.options"
              height="250"
              type="area"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Riesgo de Datos -->
    <div class="graphics-container-cuatriple">
      <div class="tway-violet--text">Riesgo de Datos</div>
      <div>
        <div>
          <div class="mx-8 mt-1 pt-2 tway-violet--text">
            <p>
              Clientes Potenciales Perdidos por Filtración de Datos
              <ToolTips
                width="300"
                description="Estimación en número de los clientes potenciales que no se convierten en clientes o usuarios activos a raíz de una filtración de datos."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="chartPotencialClientsLost"
              :series="chartPotencialClientsLost.series"
              :options="chartPotencialClientsLost.options"
              width="500"
              height="300"
              type="area"
            />
          </div>
        </div>
        <div>
          <div class="mx-8 mt-1 pt-2 tway-violet--text">
            <p>
              Rotación de Clientes por Filtración de Datos
              <ToolTips
                width="260"
                description="Cantidad de clientes nuevos que reemplazan a los que se retiran del servicio o negocio a causa de una filtración de datos."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              :series="chartCustomerRotationDB.series"
              :options="chartCustomerRotationDB.options"
              height="335"
              type="radialBar"
            />
          </div>
        </div>
      </div>
      <div>
        <div>
          <div class="mx-8 mt-1 pt-2 tway-violet--text">
            <p>
              Eventos de Riesgo Críticos por Manejo de Datos
              <ToolTips
                width="260px"
                description="Cantidad de Incidentes que ponen en riesgo la continuidad operativa a cause de deficiencias en el manejo de data en una empresa."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              :series="chartCriticalRiskEvents.series"
              :options="chartCriticalRiskEvents.options"
              height="335"
              type="radialBar"
            />
          </div>
        </div>
        <div>
          <div class="mx-8 mt-1 pt-2 tway-violet--text">
            <p>
              Tiempo de Detección de Filtración de Datos
              <ToolTips
                width="260px"
                description="Tiempo en minutos que toma identificar y alertar sobre un evento que pone en riesgo la data de una empresa."
              />
            </p>
          </div>
          <div>
            <VueApexCharts
              ref="chartQuickResponseDR"
              :series="chartQuickResponseDR.series"
              :options="chartQuickResponseDR.options"
              width="500"
              height="300"
              type="area"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Seguimiento from "../../components/pmo/performance/seguimiento.vue";
import PerformanceService from "@/services/pmo/performance/performanceService";
import VueApexCharts from "vue-apexcharts";
import ToolTips from "../../components/pmo/utils/ToolTips";

export default {
  name: "Engagement",
  components: {
    Seguimiento,
    VueApexCharts,
    ToolTips,
  },
  data() {
    return {
      chartIncorporation: {
        series: [],
        chartOptions: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radialBar",
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              hollow: {
                margin: 0,
                size: "55%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
              },
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "36px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            colors: ["#7319D5"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },
      chartYield: {
        series: [],
        chartOptions: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radialBar",
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              hollow: {
                margin: 0,
                size: "55%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
              },
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "36px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            colors: ["#16C62E"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },
      chartRotation: {
        series: [],
        chartOptions: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radialBar",
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              hollow: {
                margin: 0,
                size: "55%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
              },
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "36px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            colors: ["#7F7F7F"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },
      chartTechAndSystemTraining: {
        series: [],
        chartOptions: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radialBar",
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              hollow: {
                margin: 0,
                size: "55%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
              },
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "36px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#7319D5"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },
      chartDigitalSkillsImprovement: {
        series: [],
        chartOptions: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radialBar",
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              hollow: {
                margin: 0,
                size: "55%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
              },
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "36px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#16C62E"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },

      chartToolsAvailability: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 350,
            type: "radialBar",
            zoom: { enabled: false },
            toolbar: { show: false, tools: { download: false } },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                      enabled: true,
                      top: 3,
                      left: 0,
                      blur: 4,
                      opacity: 0.24
                    }*/
              },
              track: {
                background: "#fff",
                strokeWidth: "100%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "36px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          fill: {
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#7319D5"],
            type: "solid",
          },
          stroke: {
            lineCap: "round",
          },
          labels: [""],
        },
      },
      chartTimeToMarket: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 350,
            type: "radialBar",
            zoom: { enabled: false },
            toolbar: { show: false, tools: { download: false } },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                      enabled: true,
                      top: 3,
                      left: 0,
                      blur: 4,
                      opacity: 0.24
                    }*/
              },
              track: {
                background: "#fff",
                strokeWidth: "100%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "36px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          fill: {
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#16C62E"],
            type: "solid",
          },
          stroke: {
            lineCap: "round",
          },
          labels: [""],
        },
      },
      chartAppAndTechAdoption: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 350,
            type: "radialBar",
            zoom: { enabled: false },
            toolbar: { show: false, tools: { download: false } },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "60%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                      enabled: true,
                      top: 3,
                      left: 0,
                      blur: 4,
                      opacity: 0.24
                    }*/
              },
              track: {
                background: "#fff",
                strokeWidth: "100%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "36px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          fill: {
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#7F7F7F"],
            type: "solid",
          },
          stroke: {
            lineCap: "round",
          },
          labels: [""],
        },
      },

      chartResolutionRate: {
        series: [],
        chartOptions: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radialBar",
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              hollow: {
                margin: 0,
                size: "55%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
              },
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: "#fff",
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "36px",
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: "solid",
            colors: ["#7319D5"],
          },
          labels: ["Average Results"],
          stroke: { lineCap: "round" },
        },
      },
      chartTimeDeviationCP: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "radialBar",
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              hollow: {
                margin: 0,
                size: "55%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
              },
              track: {
                background: "#e7e7e7",
                strokeWidth: "97%",
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  blur: 2,
                  opacity: 1,
                  color: "#fff",
                },
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: "36px",
                },
              },
            },
          },
          fill: {
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#16C62E"],
            type: "solid",
          },
          labels: ["%"],
          stroke: { lineCap: "round" },
        },
      },
      chartHourToResolveCI: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 450,
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"],
            },
          },
          xaxis: {
            categories: [],
            labels: {
              formatter: function (val) {
                return val;
              },
            },
          },
          colors: ["#7F7F7F"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#7F7F7F",
              },
              labels: { style: { colors: "#7F7F7F" } },
              title: {
                text: "Hora",
                style: { color: "#7F7F7F" },
              },
              tooltip: { enabled: true },
            },
          ],
          tooltip: {
            x: {
              formatter: function (val) {
                return val;
              },
            },
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },

      chartPotencialClientsLost: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 350,
            type: "area",
            stacked: false,
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: [0, 2, 5, 2],
            curve: "smooth",
          },
          plotOptions: {
            bar: { columnWidth: "65%" },
          },
          markers: { size: 3 },
          xaxis: {
            categories: [],
          },
          colors: ["#7319D5"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              seriesName: "Cantidad de Clientes",
              opposite: false,
              axisTicks: { show: true },
              axisBorder: { show: true, color: "#7319D5" },
              title: {
                text: "Cantidad de Clientes",
                style: { color: "#7319D5" },
              },
              labels: {
                style: { color: "#7319D5" },
              },
            },
          ],
          tooltip: {
            shared: true,
            intersect: false,
          },
        },
      },
      chartCustomerRotationDB: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 350,
            type: "radialBar",
            zoom: { enabled: false },
            toolbar: { show: false, tools: { download: false } },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "65%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                      enabled: true,
                      top: 3,
                      left: 0,
                      blur: 4,
                      opacity: 0.24
                    }*/
              },
              track: {
                background: "#fff",
                strokeWidth: "100%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "36px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          fill: {
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#16C62E"],
            type: "solid",
          },
          stroke: {
            lineCap: "round",
          },
          labels: [""],
        },
      },
      chartCriticalRiskEvents: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 350,
            type: "radialBar",
            zoom: { enabled: false },
            toolbar: { show: false, tools: { download: false } },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "65%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                /*dropShadow: {
                      enabled: true,
                      top: 3,
                      left: 0,
                      blur: 4,
                      opacity: 0.24
                    }*/
              },
              track: {
                background: "#fff",
                strokeWidth: "100%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.05,
                },
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "17px",
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "36px",
                  show: true,
                  offsetY: 0,
                },
              },
            },
          },
          fill: {
            //colors: [ '#7F7F7F', '#4A148C','#16C62E', '#7319D5', '#AEEA00' ],
            colors: ["#7F7F7F"],
            type: "solid",
          },
          stroke: {
            lineCap: "round",
          },
          labels: [""],
        },
      },
      chartQuickResponseDR: {
        series: [],
        options: {
          series: [],
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 300,
            type: "area",
            zoom: { enabled: false },
            toolbar: {
              show: false,
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            width: [2],
            curve: "smooth",
            dashArray: [0],
          },
          markers: { size: 3 },
          title: {
            text: undefined,
          },
          grid: {
            row: {
              colors: ["#f3f3f3"], // takes an array which will be repeated on columns
            },
          },
          xaxis: {
            categories: [],
          },
          // colors: [
          //   "#7319D5",
          //   "#B2B2B2",
          //   "#AEEA00",
          //   "#4A148C",
          //   "#7F7F7F",
          //   "#16C62E",
          // ],
          colors: ["#AEEA00"],
          fill: {
            colors: undefined,
            opacity: 0.9,
            type: "solid",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 100],
              colorStops: [],
            },
            image: {
              src: [],
              width: undefined,
              height: undefined,
            },
            pattern: {
              style: "verticalLines",
              width: 6,
              height: 6,
              strokeWidth: 2,
            },
          },
          yaxis: [
            {
              axisTicks: { show: true },
              axisBorder: {
                show: true,
                color: "#AEEA00",
              },
              labels: { style: { colors: "#AEEA00" } },
              title: {
                text: "Minutos",
                style: { color: "#AEEA00" },
              },
              tooltip: { enabled: true },
              //   max: 100,
              //   min: 0,
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60,
            },
          },
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
    };
  },

  methods: {
    reloadCharts() {
      this.callService();
    },
    async callService() {
      await PerformanceService.getChartHourToResolve(this.project)
        .then((data) => {
          this.chartHourToResolveCI.series = data.series;
          this.$refs.chartHourToResolveCI.updateOptions({
            xaxis: {
              categories: data.series[0].categories,
            },
          });
        })
        .catch((err) => console.dir(err));
      await PerformanceService.getChartPotentialClientsLots(this.project)
        .then((data) => {
          this.chartPotencialClientsLost.series = data.series;
          this.$refs.chartPotencialClientsLost.updateOptions({
            xaxis: {
              categories: data.series[0].categories,
            },
          });
        })
        .catch((err) => console.dir(err));

      await PerformanceService.getChartQuickResponses(this.project)
        .then((data) => {
          this.chartQuickResponseDR.series = data.series;
          this.$refs.chartQuickResponseDR.updateOptions({
            xaxis: {
              categories: data.series[0].categories,
            },
          });
        })
        .catch((err) => console.dir(err));

      await PerformanceService.getChartClientRotation(this.project)
        .then((data) => {
          this.chartCustomerRotationDB.series = data.series;
        })
        .catch((err) => console.dir(err));
      await PerformanceService.getChartCriticalRiskEvents(this.project)
        .then((data) => {
          this.chartCriticalRiskEvents.series = data.series;
        })
        .catch((err) => console.dir(err));
      await PerformanceService.getChartIncorporation(this.project)
        .then((data) => {
          this.chartIncorporation.series = data.series;
        })
        .catch((err) => console.dir(err));
      await PerformanceService.getChartYield(this.project)
        .then((data) => {
          this.chartYield.series = data.series;
        })
        .catch((err) => console.dir(err));
      await PerformanceService.getChartRotation(this.project)
        .then((data) => {
          this.chartRotation.series = data.series;
        })
        .catch((err) => console.dir(err));
      await PerformanceService.getChartToolsAvailability(this.project)
        .then((data) => {
          this.chartToolsAvailability.series = data.series;
        })
        .catch((err) => console.dir(err));
      await PerformanceService.getChartTechAndSystemTraining(this.project)
        .then((data) => {
          this.chartTechAndSystemTraining.series = data.training.series;
          this.chartDigitalSkillsImprovement.series = data.skills.series;
          this.chartTimeToMarket.series = data.innovation.series;
          this.chartAppAndTechAdoption.series = data.adoption.series;
        })
        .catch((err) => console.dir(err));
      await PerformanceService.getChartResolutionRate(this.project)
        .then((data) => {
          this.chartResolutionRate.series = data.series;
        })
        .catch((err) => console.dir(err));
      await PerformanceService.getChartTimeDeviation(this.project)
        .then((data) => {
          this.chartTimeDeviationCP.series = data.series;
        })
        .catch((err) => console.dir(err));
    },
  },
  async mounted() {
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.project = pmoProjectIds;

    this.callService();
  },
};
</script>

<style lang="scss" scoped>
.box-graphics {
  max-width: 1400px;
  min-width: 1100px;
}

.graphics {
  display: flex;
  flex-direction: column;
}

.shrink {
  width: 150px;
}

.bar {
  width: 350px;
}

.resumen {
  display: flex;
  width: 100%;
  height: 250px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  justify-content: center;
  margin: 25px 0px 25px 0px;
}

.resumen > .resumen-detail {
  width: 17%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.resumen > .resumen-detail > .resumen-detail-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.resumen > .resumen-detail > .resumen-detail-item > div:first-child {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 55%;
}

.resumen > .resumen-detail > .resumen-detail-item > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 15%;
}

.resumen > .resumen-bar {
  display: flex;
  width: 60%;
  height: auto;
}

.resumen-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: solid black 1px;
  justify-content: space-between;
}

.resumen-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: solid black 1px;
  justify-content: space-between;
  margin-bottom: 15px;
}

.seguimiento {
  width: 100%;
  display: flex;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  justify-content: center;
  margin: 25px 0px 25px 0px;
  height: 500px;
}

.seguimiento-costo {
  width: 45%;
  margin: 2px;
}

.seguimiento-periodo {
  width: 45%;
  margin: 2px;
}

.graphics-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  margin: 25px 0px 25px 0px;
  div {
    display: flex;
    flex-direction: row;
  }
  p {
    font-size: 16px;
  }
}

.graphics-container-duo,
.graphics-container-triple,
.graphics-container-cuatriple {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  padding: 30px 20px 0px 20px;
  margin-top: 50px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 20px;
  margin: 25px 0px 25px 0px;
  div {
    display: flex;
    flex-direction: row;
  }
  p {
    font-size: 16px;
  }
}

.graphics-container-duo {
  div {
    div {
      display: flex;
      flex-direction: column;
      width: 45%;

      div {
        width: 100%;
      }

      //border: 1px solid black;
    }
  }
}

.graphics-container-triple {
  div {
    div {
      width: 33%;
      display: flex;
      flex-direction: column;
      div {
        width: 100%;
      }

      //border: 1px solid black;
    }
  }
}
.graphics-container-cuatriple {
  div {
    div {
      width: 50%;
      display: flex;
      flex-direction: column;
      div {
        width: 100%;
      }

      //border: 1px solid black;
    }
  }
}
.padding-fix {
  padding-bottom: 20px;
}
.box-container {
  display: flex;
  align-items: stretch;
  width: inherit;
}
.box-container > .inside-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
}
.inside-box > div {
  padding-top: auto;
  margin-top: auto;
}
</style>
